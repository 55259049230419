<template>
    <div class="view report-bug">
        <page-section :background="sectionBg">
            <br><br>
            <h2>Submit a Log</h2>
            <p>Thank you for supporting the game!<br> Below you will find instructions and a form to report a bug with a
                log file.</p>
            <h4>Enabling log-files</h4>
            <p>You can enable log files by <strong>adding</strong> DEBUG_STUFF_ENABLED=true within the [Configuration]
                block
                in the Custom/game.ini file.<br> You can find generated logs in <strong>C:\Users\[Username]\AppData\LocalLow\PupBlake\Arctic
                    Wolves\logs</strong> after the game is closed.</p>
            <img :src="require('@/assets/images/debug_config.jpg')" width="300" alt="Configuration file">
            <hr>
            <h4>Submit a Log</h4>
            <p>Please use this Google Form (anonymous) to submit a report: <a target="_blank"
                                                                              href="https://docs.google.com/forms/d/e/1FAIpQLScdk3GJgIENKqkiVpxkM_CCrOn-vU6osKiYqt1YojgJPRlVEw/viewform?usp=pp_url&entry.1911896157=0.0.98">Report
                form</a>.<br>You can only paste a link to the log in the Google form, so please upload your log file on
                <a target="_blank"
                   href="https://pastebin.com/">Pastebin</a> or some other hoster and fill the link in the form.</p>
        </page-section>
    </div>
</template>

<script>
import Roadmap     from "@/components/Roadmap.vue";
import Slider      from "@/components/Slider.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import PageSection from "@/components/PageSection.vue";

export default {
    name:       "ReportBug",
    components: { PageSection, VideoPlayer, Slider, Roadmap },
    computed:   {
        sectionBg() {
            return require('@/assets/images/dancing.webp');
        },
    },
};
</script>
