import { createRouter, createWebHashHistory } from 'vue-router';
import Home                                   from '@/views/Home.vue';
import ReportBug                              from '@/views/ReportBug.vue';

const routes = [
    {
        path:      '/',
        name:      'Home',
        component: Home
    },
    {
        path:      '/report-bug',
        name:      'ReportBug',
        component: ReportBug
    },
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from) => {
    if (to.name !== 'Home' && to.name !== 'ReportBug') {
        return { name: 'Home' };
    }
});

export default router;
